import { RouteRecordRaw } from "vue-router";

const interest: Array<RouteRecordRaw> = [
  {
    path: "/interests/list",
    name: "interestList",
    meta: { requiresAuth: true },
    component: () => import("@/views/interest/InterestList.vue"),
  },
  {
    path: "/interests/create",
    name: "interestCreate",
    meta: { requiresAuth: true },
    component: () => import("@/views/interest/InterestView.vue"),
  },
  {
    path: "/interests/:id",
    name: "interestView",
    meta: { requiresAuth: true },
    component: () => import("@/views/interest/InterestView.vue"),
  },
];

export default interest;
