import { RouteRecordRaw } from "vue-router";

const user: Array<RouteRecordRaw> = [
  {
    path: "/users/list",
    name: "userList",
    meta: { requiresAuth: true },
    component: () => import("@/views/user/UserList.vue"),
  },
  {
    path: "/users/create",
    name: "userCreateProfile",
    meta: { requiresAuth: true },
    component: () => import("@/views/user/UserProfile.vue"),
  },
  {
    path: "/users/:id",
    name: "userProfile",
    meta: { requiresAuth: true },
    component: () => import("@/views/user/UserProfile.vue"),
  },
];

export default user;
