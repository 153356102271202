import { UPDATE_MODERATION } from "@/store/types";
import { ModerationState, RootState } from "@/interfaces/store";
import { Module, MutationTree } from "vuex";

const state: ModerationState = {
  complaints: 0,
  autoComplaints: 0,
  userModeration: 0,
  nudeContent: 0,
};

const mutations: MutationTree<ModerationState> = {
  [UPDATE_MODERATION](state, { type, value }) {
    state[type as keyof ModerationState] = value;
  },
};

export const moderation: Module<ModerationState, RootState> = {
  namespaced: true,
  state,
  mutations,
};
