import axios from "axios";
import config from "@/services/settings/config";

export default () => {
  const Api = axios.create(config);

  Api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { data } = error.response;
        return Promise.reject(data);
      }
      return Promise.reject(error);
    }
  );
  return Api;
};
