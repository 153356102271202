import { RouteRecordRaw } from "vue-router";

const story: Array<RouteRecordRaw> = [
  {
    path: "/present/list",
    name: "presentList",
    meta: { requiresAuth: true },
    component: () => import("@/views/present/PresentList.vue"),
  },
  {
    path: "/present/create",
    name: "presentCreate",
    meta: { requiresAuth: true },
    component: () => import("@/views/present/PresentView.vue"),
  },
  {
    path: "/present/:id",
    name: "presentView",
    meta: { requiresAuth: true },
    component: () => import("@/views/present/PresentView.vue"),
  },
];

export default story;
