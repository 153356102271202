import AuthenticationService from "@/services/AuthenticationService";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default function useLogout() {
  const store = useStore();
  const router = useRouter();

  const logout = async () => {
    try {
      await AuthenticationService.logout();
      store.commit("user/REMOVE_USER_STATE");
      router.push({ name: "login" });
    } catch (e) {
      console.log(e);
    }
  };
  return {
    logout,
  };
}
