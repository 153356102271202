import { RouteRecordRaw } from "vue-router";

const moderation: Array<RouteRecordRaw> = [
  {
    path: "/moderation/list",
    name: "moderationList",
    meta: { requiresAuth: true },
    component: () => import("@/views/moderation/ModerationList.vue"),
  },
  {
    path: "/moderation/complaint/:id",
    name: "complaintView",
    meta: { requiresAuth: true },
    component: () => import("@/views/moderation/ComplaintView.vue"),
  },
  {
    path: "/moderation/lexicon/create",
    name: "lexiconView",
    meta: { requiresAuth: true },
    component: () => import("@/views/moderation/LexiconView.vue"),
  },
  {
    path: "/moderation/user/:id",
    name: "userProfileModerate",
    meta: { requiresAuth: true },
    component: () => import("@/views/moderation/UserProfileView.vue"),
  },
];

export default moderation;
