import DictionaryUserService from "@/services/DictionaryUserService";
import { useStore } from "vuex";

export default function useDictionary() {
  const height: { id: number; title: string }[] = [],
    weight: { id: number; title: string }[] = [],
    kids: { id: number; title: string }[] = [
      {
        id: 0,
        title: "Хочу детей",
      },
      {
        id: 1,
        title: "Уже есть",
      },
    ];

  const store = useStore();

  const getDictionary = async () => {
    try {
      const res = await Promise.all([
        DictionaryUserService.getSmokings(),
        DictionaryUserService.getAlcohols(),
        DictionaryUserService.getBodyTypes(),
        DictionaryUserService.getEducations(),
        DictionaryUserService.getGenders(),
        DictionaryUserService.getNationality(),
        DictionaryUserService.getSport(),
        DictionaryUserService.getReligions(),
        DictionaryUserService.getComplaints(),
        DictionaryUserService.getCategories(),
        DictionaryUserService.getSlideTypes(),
      ]);

      const dictionaries = [
        "smokings",
        "alcohols",
        "bodyTypes",
        "educations",
        "genders",
        "nationality",
        "sport",
        "religions",
        "complaints",
        "categories",
        "slideTypes",
      ];

      dictionaries.forEach((i, key) => {
        store.commit("dictionary/UPDATE_DICTIONARY", {
          type: i,
          value: res[key].data,
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  for (let i = 130; i <= 240; i++) {
    height.push({
      id: i,
      title: i + " см",
    });
  }

  for (let i = 50; i <= 140; i++) {
    weight.push({
      id: i,
      title: i + " кг",
    });
  }

  store.commit("dictionary/UPDATE_DICTIONARY", {
    type: "height",
    value: height,
  });

  store.commit("dictionary/UPDATE_DICTIONARY", {
    type: "weight",
    value: weight,
  });

  store.commit("dictionary/UPDATE_DICTIONARY", {
    type: "kids",
    value: kids,
  });

  return {
    getDictionary,
  };
}
