import Api from "@/services/settings/Api";
import ApiAuth from "@/services/settings/ApiAuth";
import { UserLoginRequest } from "@/interfaces/userLogin";

class AuthenticationService {
  login(user_data: UserLoginRequest) {
    return Api().post("api-admin/login", user_data);
  }

  logout() {
    return ApiAuth().post("api-admin/logout");
  }
}

export default new AuthenticationService();
