import { UPDATE_TOKEN, UPDATE_USER, REMOVE_USER_STATE } from "@/store/types";
import { UserState, RootState } from "@/interfaces/store";
import { Module, MutationTree } from "vuex";

function getUser() {
  const user = localStorage.getItem("user");
  if (user !== null) return JSON.parse(user);
  else
    return {
      id: "",
      phone: "",
      email: "",
      email_verified_at: null,
      visited_at: null,
      group_id: null,
      created_at: null,
      updated_at: null,
      deleted_at: null,
      lat: null,
      lon: null,
    };
}

const state: UserState = {
  token: localStorage.getItem("token") || "",
  user: getUser(),
};

const mutations: MutationTree<UserState> = {
  [UPDATE_TOKEN](state, token) {
    state.token = token;
    localStorage.setItem("token", token);
  },

  [UPDATE_USER](state, user) {
    state.user = {
      id: user.id,
      phone: user.phone,
      email: user.email,
      email_verified_at: user.email_verified_at,
      visited_at: user.visited_at,
      group_id: user.group_id,
      created_at: user.created_at,
      updated_at: user.updated_at,
      deleted_at: user.deleted_at,
      lat: user.lat,
      lon: user.lon,
    };
    localStorage.setItem("user", JSON.stringify(user));
  },

  [REMOVE_USER_STATE](state) {
    state.token = "";
    state.user = {
      id: "",
      phone: "",
      email: "",
      email_verified_at: null,
      visited_at: null,
      group_id: null,
      created_at: null,
      updated_at: null,
      deleted_at: null,
      lat: null,
      lon: null,
    };
    localStorage.clear();
  },
};

export const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  mutations,
};
