import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import user from "@/router/modules/user";
import interest from "@/router/modules/interest";
import payment from "@/router/modules/payment";
import moderation from "@/router/modules/moderation";
import story from "@/router/modules/story";
import present from "@/router/modules/present";
import notify from "@/router/modules/notify";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { name: "userList" },
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false },
    component: () => import("@/views/Login.vue"),
  },
  ...user,
  ...interest,
  ...payment,
  ...moderation,
  ...present,
  ...story,
  ...notify,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  if (to.meta.requiresAuth && !localStorage.getItem("user")) {
    return { name: "login", query: { redirect: to.fullPath } };
  }
});

export default router;
