// user
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_USER = "UPDATE_CURRENT_USER";
export const REMOVE_USER_STATE = "REMOVE_USER_STATE";

// dictionary
export const UPDATE_DICTIONARY = "UPDATE_DICTIONARY";

// moderation
export const UPDATE_MODERATION = "UPDATE_MODERATION";
