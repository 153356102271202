import { createStore } from "vuex";
import { RootState } from "@/interfaces/store";
import { user } from "@/store/modules/user";
import { dictionary } from "@/store/modules/dictionary";
import { moderation } from "@/store/modules/moderation";

export default createStore<RootState>({
  modules: {
    user,
    dictionary,
    moderation,
  },
});
