import { UPDATE_DICTIONARY } from "@/store/types";
import { DictionaryState, RootState } from "@/interfaces/store";
import { Module, MutationTree } from "vuex";

const state: DictionaryState = {
  smokings: [],
  alcohols: [],
  sport: [],
  educations: [],
  bodyTypes: [],
  genders: [],
  religions: [],
  height: [],
  weight: [],
  kids: [],
  nationality: [],
  complaints: [],
  categories: [],
  slideTypes: [],
};

const mutations: MutationTree<DictionaryState> = {
  [UPDATE_DICTIONARY](state, { type, value }) {
    state[type as keyof DictionaryState] = value;
  },
};

export const dictionary: Module<DictionaryState, RootState> = {
  namespaced: true,
  state,
  mutations,
};
