import { RouteRecordRaw } from "vue-router";

const payment: Array<RouteRecordRaw> = [
  {
    path: "/payment/price/list",
    name: "paymentList",
    meta: { requiresAuth: true },
    component: () => import("@/views/payment/PaymentList.vue"),
  },
  {
    path: "/payment/price/:id",
    name: "paymentView",
    meta: { requiresAuth: true },
    component: () => import("@/views/payment/PaymentView.vue"),
  },
  {
    path: "/payment/presents/list",
    name: "presentList",
    meta: { requiresAuth: true },
    component: () => import("@/views/present/PresentList.vue"),
  },
  {
    path: "/payment/presents/create",
    name: "presentCreate",
    meta: { requiresAuth: true },
    component: () => import("@/views/present/PresentView.vue"),
  },
  {
    path: "/payment/presents/:id",
    name: "presentView",
    meta: { requiresAuth: true },
    component: () => import("@/views/present/PresentView.vue"),
  },
];

export default payment;
