import { AxiosRequestConfig } from "axios";

const config: AxiosRequestConfig = {
  baseURL: "https://api.поженимся.online/",
  //baseURL: "http://wedding.local/",
  timeout: 1200000,
  withCredentials: false,
  responseType: "json",
  maxContentLength: 2000,
  validateStatus: function (status: number) {
    return status >= 200 && status < 300;
  },
  maxRedirects: 5,
};

export default config;
