import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as dayjs from "dayjs";
import "dayjs/locale/ru";
import { mask } from "vue-the-mask";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ElTableInfiniteScroll from "el-table-infinite-scroll";

const app = createApp(App);

app.use(store);
app.use(router);
dayjs.locale("ru");
app.provide("dayjs", dayjs);
app.directive("mask", mask as any);
app.use(CKEditor);
app.use(ElTableInfiniteScroll);

app.mount("#app");
