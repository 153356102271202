import ApiAuth from "@/services/settings/ApiAuth";

class UserService {
  getNationality() {
    return ApiAuth().get("api/nationality");
  }

  getGenders() {
    return ApiAuth().get("api/genders");
  }

  getBodyTypes() {
    return ApiAuth().get("api/body-types");
  }

  getEducations() {
    return ApiAuth().get("api/educations");
  }

  getSport() {
    return ApiAuth().get("api/sport");
  }

  getAlcohols() {
    return ApiAuth().get("api/alcohols");
  }

  getSmokings() {
    return ApiAuth().get("api/smokings");
  }

  getReligions() {
    return ApiAuth().get("api/religions");
  }

  getComplaints() {
    return ApiAuth().get("api/complaint-types");
  }

  getCategories() {
    return ApiAuth().get("api/story-categories");
  }

  getSlideTypes() {
    return ApiAuth().get("api/slide-types");
  }
}

export default new UserService();
