import axios from "axios";
import config from "@/services/settings/config";
import store from "@/store";
import router from "@/router/index";

export default () => {
  const { token: Token } = store.state.user;
  const ApiAuth = axios.create({
    ...config,
    headers: {
      Authorization: Token,
    },
  });

  ApiAuth.interceptors.request.use(
    (config) => {
      if (!Token) return Promise.reject(401);
      return config;
    },
    (error) => {
      console.log("before axios error");
      return Promise.reject(error);
    }
  );

  ApiAuth.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 401) {
          store.commit("REMOVE_USER_STATE");
          router.push({ name: "login" });
        }

        if (status === 403) return Promise.reject(403);

        if (status === 409) return Promise.reject(error);
        return Promise.reject(data);
      }

      if (error.message === "Network Error") {
        console.log("global error");
      }

      return Promise.reject(error);
    }
  );
  return ApiAuth;
};
