import { RouteRecordRaw } from "vue-router";

const notify: Array<RouteRecordRaw> = [
  {
    path: "/notify/list",
    name: "notifyList",
    meta: { requiresAuth: true },
    component: () => import("@/views/notify/NotifyList.vue"),
  },
];

export default notify;
