import { RouteRecordRaw } from "vue-router";

const story: Array<RouteRecordRaw> = [
  {
    path: "/story/list",
    name: "storyList",
    meta: { requiresAuth: true },
    component: () => import("@/views/story/StoryList.vue"),
  },
  {
    path: "/story/create",
    name: "storyCreate",
    meta: { requiresAuth: true },
    component: () => import("@/views/story/StoryView.vue"),
  },
  {
    path: "/story/:id",
    name: "storyView",
    meta: { requiresAuth: true },
    component: () => import("@/views/story/StoryView.vue"),
  },
];

export default story;
